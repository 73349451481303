import { Link } from 'wouter-preact'

import routes from '../../routes.js'
import { useAuth } from '../../Context/AuthProvider.jsx'
import { withPagePermissions } from '../../Components/Guard/index.jsx'

import { useDelete } from '../../Hooks/Api/useRestApi.js'
import { useOrganisations } from '../../Context/Organisations.jsx'

import State from '../../Components/State/State.jsx'
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb.jsx'
import Icon from '../../Components/Icon/Icon.jsx'
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay.jsx'
import DateTime from '../../Components/DateTime/DateTime.jsx'
import PERMISSION from '../Organisation/organisation.permission.js'

import { confirm } from '../../utils/modal.js'

import styles from './organisations.module.css'

/**
 * @typedef { import('../../Hooks/Api/useRestApi.js').DeleteResponse<App.Entity.Organisation> } OrganisationDeleteResponse
 */

/** @type {App.Entity.Organisation[]} */
const initialData = []

/**
 * Organisations collection page
 *
 * @type {preact.FunctionComponent}
 */
function OrganisationIndex() {
  const { items = initialData, isLoading, error: loadingError, mutate } = useOrganisations()

  /** @type {OrganisationDeleteResponse} */
  const { isDeleting, deleter } = useDelete('/organisations', undefined)

  const { isGranted, user } = useAuth()

  /**
   * @param {App.Entity.Organisation} item
   */
  async function handleDeleteClick(item) {
    if (isDeleting) {
      return
    }

    if (!await confirm()) {
      return
    }

    await deleter(item)
    await mutate(cachedItems =>
      cachedItems && cachedItems.filter(cachedItem => cachedItem.id !== item.id)
    )
  }

  return (
    <section className={`uk-article ${styles.organisation}`}>

      {/** Breadcrumbs */}
      <Breadcrumb items={[ {text: 'Organisations'} ]} />

      <div className="uk-card uk-card-default uk-card-small">
        <div className="uk-card-header uk-flex uk-flex-between">
          <h1 className="uk-card-title uk-margin-remove">
            {'Organisations'}
          </h1>

          {/** Actions */}
          <ul className="uk-iconnav">
            {isGranted(PERMISSION.CREATE) &&
              <li>
                <Link
                  href={routes.organisation.create}
                  className="uk-icon"
                  data-uk-icon="icon: plus"
                  data-uk-tooltip={'Add'}
                />
              </li>
            }
          </ul>
        </div>

        <div className="uk-card-body uk-overflow-auto">
          <table className="uk-table uk-table-divider uk-table-striped uk-table-hover uk-table-small uk-table-middle uk-table-responsive">
            <thead>
              <tr>
                <th className="uk-table-shrink">{'id'}</th>
                <th className="uk-table-expand">{'Name'}</th>
                <th className="uk-table-shrink">{'Language'}</th>
                <th className="uk-table-shrink">{'State'}</th>
                <th className="uk-table-shrink">{'Plan type'}</th>
                <th className="uk-table-shrink">{'Plan Started'}</th>
                <th className="uk-table-shrink">{'Actions'}</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, key) =>
                <tr key={key}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.language}</td>
                  <td><State state={item.state} /></td>
                  <td>{item.plan.code || '-'}</td>
                  <td><DateTime timestamp={item.plan.startedAt} asDate /></td>
                  <td>
                    <fieldset
                      className="uk-fieldset uk-text-right uk-text-left@m"
                      disabled={isDeleting}
                    >
                      <div className="uk-button-group">
                        {isGranted(PERMISSION.UPDATE) &&
                          <Link
                            href={routes.organisation.update.replace(':id', item.id.toString())}
                            className="uk-button uk-button-primary uk-button-small"
                          >
                            <Icon icon="pencil" />
                          </Link>
                        }
                        {isGranted(PERMISSION.DELETE) && item.id !== user.organisationId &&
                          <button
                            className="uk-button uk-button-danger uk-button-small"
                            type="button"
                            onClick={() => handleDeleteClick(item)}
                          >
                            <Icon icon="trash" />
                          </button>
                        }
                      </div>
                    </fieldset>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/** Overlays */}
        <LoadingOverlay
          loadingError={loadingError}
          isLoading={isLoading}
        />

      </div>
    </section>
  )
}

export default withPagePermissions(OrganisationIndex, [PERMISSION.INDEX])
