import { Link } from 'wouter-preact'
import { connect, Form } from 'formik'

import routes from '../../routes.js'
import { useAuth } from '../../Context/AuthProvider.jsx'
import { withPagePermissions } from '../../Components/Guard/index.jsx'
import PERMISSION from './user.permission.js'
import { GLOBAL_CONTEXT, ORGANISATION } from '../../Constants/permission.js'

import { ROLE } from './User.constants.js'
import STATE from '../../Components/State/state.constants.js'
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb.jsx'
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay.jsx'
import SelectOrganisation from '../../Components/Form/SelectOrganisation.jsx'
import ValidationMessages from '../../Components/Form/ValidationMessages.jsx'

/**
 * Create/ Update
 *
 * @TODO Add password, but don't store in state
 *
 * @type {preact.FunctionComponent}
 * @param {Object} props
 * @param {number|null} props.id
 * @param {Error|undefined} props.loadingError
 * @param {boolean} props.isLoading
 * @param {boolean} props.isSyncing
 * @param {import('formik').FormikContextType<any>} props.formik
 */
function UserForm({
  id,
  loadingError = undefined,
  isLoading = false,
  isSyncing = false,
  formik,
}) {
  const { isGranted } = useAuth()

  return (
    <section className="uk-article">

      {/** Breadcrumbs */}
      <Breadcrumb items={[
        { text: 'Users', path: routes.user.index },
        { text: id || 'New item' }
      ]} />

      <Form className="uk-card uk-card-default uk-card-small uk-flex-inline uk-flex-column">
        <fieldset
          className="uk-fieldset"
          disabled={isLoading || isSyncing}
        >
          <div className="uk-card-header uk-flex uk-flex-between">
            <h1 className="uk-card-title uk-margin-remove">
              {id ? 'Edit User' : 'Create User'}
            </h1>

            {/** Actions */}
            <ul className="uk-iconnav">
              <li>
                <button
                  className="uk-icon"
                  type="submit"
                  data-uk-icon="icon: check"
                  data-uk-tooltip={'Save'}
                ></button>
              </li>
              <li>
                <Link
                  className="uk-icon"
                  href={routes.user.index}
                  data-uk-icon="icon: close"
                  data-uk-tooltip={'Cancel'}
                />
              </li>
            </ul>
          </div>

          <div className="uk-card-body uk-form-horizontal">
            {/** Email */ }
            <div className="uk-margin">
              <label className="uk-form-label">{'Email'}</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-width-medium"
                  type="email"
                  placeholder={'john@doe.com'}
                  autoComplete="username"
                  readOnly={id !== null}
                  {...formik.getFieldProps('email')}
                />
                <ValidationMessages name="email" />
              </div>
            </div>

            {/** Password */}
            <div className="uk-margin">
              <label className="uk-form-label">{'Password'}</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-width-medium"
                  type="password"
                  placeholder={'Password'}
                  autoComplete="new-password"
                  {...formik.getFieldProps('password')}
                />
                <ValidationMessages name="password" />
              </div>
            </div>

            {/** Confirm Password */}
            <div className="uk-margin">
              <label className="uk-form-label">{'Confirm password'}</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-width-medium"
                  type="password"
                  placeholder={'Password'}
                  autoComplete="new-password"
                  {...formik.getFieldProps('passwordConfirm')}
                />
                <ValidationMessages name="passwordConfirm" />
              </div>
            </div>

            {/** Name */ }
            <div className="uk-margin">
              <label className="uk-form-label">{'Name'}</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-width-medium"
                  type="text"
                  placeholder={'John Doe'}
                  {...formik.getFieldProps('name')}
                />
                <ValidationMessages name="name" />
              </div>
            </div>

            {/** Organisation Id */ }
            {isGranted(GLOBAL_CONTEXT.ACCESS) &&
              <div className="uk-margin">
                <label className="uk-form-label">{'Organisation'}</label>
                <div className="uk-form-controls">
                  <SelectOrganisation name="organisationId" />
                </div>
              </div>
            }

            {/** Roles */ }
            <div className="uk-margin">
              <label className="uk-form-label">{'Roles'}</label>
              <div className="uk-form-controls">
                <select
                  className="uk-select uk-form-width-medium"
                  multiple
                  size={3}
                  {...formik.getFieldProps('roles')}
                >
                  {isGranted(PERMISSION.ROLE_ROOT__SET) && <option value={ROLE.ROOT}>{'Root'}</option>}
                  <option value={ROLE.MANAGER}>{'Manager'}</option>
                  <option value={ROLE.USER}>{'User'}</option>
                  <option value={ROLE.SERVICE}>{'Service'}</option>
                </select>
                <ValidationMessages name="roles" />
              </div>
            </div>

            {/** Language */ }
            <div className="uk-margin">
              <label className="uk-form-label">{'Language'}</label>
              <div className="uk-form-controls">
                <select
                  className="uk-select uk-form-width-medium"
                  {...formik.getFieldProps('language')}
                >
                  <option value="">{'Default'}</option>
                  <option value="en-GB">{'English'}</option>
                  <option value="pl-PL">{'Polish'}</option>
                </select>
                <ValidationMessages name="language" />
              </div>
            </div>

            {/** State */ }
            <div className="uk-margin">
              <label className="uk-form-label">{'State'}</label>
              <div className="uk-form-controls">
                <select
                  className="uk-select uk-form-width-medium"
                  disabled
                  {...formik.getFieldProps('state')}
                >
                  <option value={STATE.ENABLED}>{'Enabled'}</option>
                  <option value={STATE.DISABLED}>{'Disabled'}</option>
                </select>
                <ValidationMessages name="state" />
              </div>
            </div>
          </div>

          {/** Overlays */}
          <LoadingOverlay
            loadingError={loadingError}
            isLoading={isLoading}
          />

        </fieldset>
      </Form>
    </section>
  )
}

export default withPagePermissions(connect(UserForm), [ORGANISATION.INDEX])
