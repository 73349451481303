import { useMemo, useRef } from 'preact/hooks'
import { Link } from 'wouter-preact'
import { connect, Form } from 'formik'
import PlaylistEditorWidget from '@biotic-presenter/playlist-editor-widget/src/index.js'
import '@biotic-presenter/playlist-editor-widget/src/index.css'

import routes from '../../routes.js'
import { useAuth } from '../../Context/AuthProvider.jsx'
import { GLOBAL_CONTEXT } from '../../Constants/permission.js'

import { usePackagesConfig } from '../../Context/PackagesConfigProvider.jsx'

import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb.jsx'
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay.jsx'
import SelectOrganisation from '../../Components/Form/SelectOrganisation.jsx'
import ValidationMessages from '../../Components/Form/ValidationMessages.jsx'

/**
 * @typedef {import('@biotic-presenter/playlist-editor-widget/src/index.js').PlaylistEditorProps } PlaylistEditorProps
 */

/**
 * @type {preact.FunctionComponent}
 * @param {Object} props
 * @param {number|null} props.id
 * @param {Error|undefined} props.loadingError
 * @param {boolean} props.isLoading
 * @param {boolean} props.isSyncing
 * @param {import('formik').FormikContextType<any>} [props.formik]
 *
 * @note Unlike screen, playlist doesn't have an option to change organisation
 */
function PlaylistForm({
  id,
  loadingError = undefined,
  isLoading = false,
  isSyncing = false,
  formik,
}) {
  const { isGranted } = useAuth()

  // Lock items to populate only once and trust components internal state
  const items = useRef(formik.values.items)
  const screenSaverRange = useRef(formik.values.screenSaverRange)

  const {
    imageServerUrl,
    videoServerUrl,
  } = usePackagesConfig()

  /** @type {PlaylistEditorProps['optionConfig']} */
  const playlistOptionConfig = useMemo(() => ({
    syngeosStation: {
      items: [],
      sensors: ['pm2_5', 'pm10', 'humidity', 'air_pressure', 'temperature', 'co', 'no2', 'so2', 'noise', 'ch2o'],
      maxSensors: 5,
      manualEntry: true,
    },
    // Pre-populate BI station items
    biStation: { items: [] },
    // Configure media servers
    image: { apiUrl: imageServerUrl },
    video: { apiUrl: videoServerUrl },
  }), [imageServerUrl, videoServerUrl])

  /** @type {PlaylistEditorProps['includeTypes']} */
  const includeTypes = useMemo(() => [
    'clock',
    'countdown',
    'image',
    'screensaver',
    'syngeos-station',
    'text',
  ], [])

  return (
    <section className="uk-article">

      {/** Breadcrumbs */}
      <Breadcrumb items={[
        { text: 'Playlists', path: routes.screen.index },
        { text: id || 'New item' }
      ]} />

      <Form className="uk-card uk-card-default uk-card-small uk-flex-inline uk-flex-column uk-responsive-width">
        <fieldset
          className="uk-fieldset"
          disabled={isLoading || isSyncing}
        >
          <div className="uk-card-header uk-flex uk-flex-between">
            <h1 className="uk-card-title uk-margin-remove">
              {id ? 'Edit' : 'Create'}
            </h1>

            {/** Actions */}
            <ul className="uk-iconnav">
              <li>
                <button
                  className="uk-icon"
                  type="submit"
                  data-uk-icon="icon: check"
                  data-uk-tooltip={'Save'}
                ></button>
              </li>
              <li>
                <Link
                  className="uk-icon"
                  href={routes.playlist.index}
                  data-uk-icon="icon: close"
                  data-uk-tooltip={'Cancel'}
                />
              </li>
            </ul>
          </div>

          <div className="uk-card-body uk-form-stacked">
            {/** Label */}
            <div className="uk-margin">
              <label className="uk-form-label">{'Label'}</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-width-medium"
                  type="text"
                  placeholder={''}
                  {...formik.getFieldProps('label')}
                />
                <ValidationMessages name="label" />
              </div>
            </div>

            {/** Organisation Id */ }
            {isGranted(GLOBAL_CONTEXT.ACCESS) &&
              <div className="uk-margin">
                <label className="uk-form-label">{'Organisation'}</label>
                <div className="uk-form-controls">
                  <SelectOrganisation name="organisationId" />
                </div>
              </div>
            }

            {/** Items */}
            <div className="uk-margin">
              <label className="uk-form-label">{'Content'}</label>
              <div className="uk-form-controls uk-overflow-auto">
                <PlaylistEditorWidget
                  language="en"
                  inputName="items"
                  includeTypes={includeTypes}
                  disabledTypes={[]}
                  optionConfig={playlistOptionConfig}
                  isDebug={false}
                  items={items.current}
                  screenSaverRange={screenSaverRange.current}
                  onChange={data => formik.setFieldValue('items', data)}
                  onScreenSaverRangeChange={data => formik.setFieldValue('screenSaverRange', data)}
                />
                <ValidationMessages name="items" />
              </div>
            </div>
          </div>
        </fieldset>

        {/** Overlays */}
        <LoadingOverlay
          loadingError={loadingError}
          isLoading={isLoading}
        />

      </Form>
    </section>
  )
}

export default connect(PlaylistForm)
