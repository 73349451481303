import { useState, useEffect } from 'preact/hooks'

import Icon from '../Icon/Icon.jsx'

/**
 * Online/ offline indicator
 * @type {preact.FunctionComponent<{
 *   heartbeat: number | null
 *   tolerance?: number
 * }>}
 */
const OnlineIndicator = ({ heartbeat, tolerance = 5 * 60 }) => {
  /** @type {ReturnType<typeof useState<boolean | null>>} */
  const [isOnline, setIsOnline] = useState(null)

  useEffect(() => {
    if (!heartbeat) {
      return
    }

    const now = Date.now()
    const expiresAt = (heartbeat + tolerance) * 1_000
    const isOnline = expiresAt >= now

    setIsOnline(isOnline)

    // Need a heartbeat update to switch from offline -> online
    if (!isOnline) {
      return
    }

    // Re-evaluate
    const timer = window.setTimeout(() => setIsOnline(false), expiresAt - now)

    return () => window.clearTimeout(timer)
  }, [heartbeat, tolerance])

  // Not available
  if (isOnline === null) {
    return null
  }

  return isOnline
    ? <Icon icon="check" className="uk-text-success" tooltip={'Device is online'} />
    : <Icon icon="close" className="uk-text-danger" tooltip={'Device is offline '} />
}

export default OnlineIndicator
